import { useEffect, useRef, useState } from 'react'
import { Container, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap'

// Order of these Chart dependencies are important!
import { Chart } from 'react-chartjs-2'
import {
    Chart as ChartJS,
    PointElement,
    LineElement,
    Title,
    Tooltip as ChartJSTooltip,
    Legend,
    TimeScale,
    Decimation,
    TooltipItem,
    ChartType,
    ChartOptions,
    Point,
    DecimationOptions,
    Filler,
    LineController,
    LinearScale,
} from 'chart.js'
import 'chartjs-adapter-luxon' //This is used by chart js streaming to handle realtime

import ParsingIcon from '@mui/icons-material/AltRouteRounded'
import Close from '@mui/icons-material/HighlightOffRounded'
import { PlaybackState, SignalType, SignalValue } from '../../../api/BrokerApi/types'
import { SignalFilter } from '../../../types/Filters'
import { ParsingType } from '../../../types/ParsingType'
import SignalValueParser from '../../../utils/SignalValueParser'
import { SignalWithParsingType } from '../../../types/SignalWithParsingType'
import { addPendingSubscription, removeSubscription } from '../../../services/BrokerService'
import { SubscriptionId } from '../../../types/SubscriptionId'
import useInterval from '../../../hooks/useInterval'
import DownsamplingIcon from '@mui/icons-material/InfoRounded'
import { utcDateTimeWithMillis } from '../../../utils/DateFormatter'

ChartJS.register(
    //Scales
    TimeScale, // x-axis
    LinearScale, // y-axis

    // Chart types
    LineController,
    LineElement,
    PointElement,

    //Plugins
    ChartJSTooltip,
    Legend,
    Title,
    Decimation,
    Filler
)

interface MultiTypeSignalContainerProps {
    onRefreshFunction: () => void
    syncChartsFunction: () => void
    containerKey: string
    chartType: 'filled' | 'line'
    signalFilter: SignalFilter
    playbackState: PlaybackState | undefined
    frameRate: number
    addSignalToHistoryFunction: (signalValue: SignalValue, parsingType: ParsingType) => void
    signalHistoryList: Array<SignalWithParsingType>
    removeContainerFunction?: () => void
    pauseLiveState: boolean
    isCloudBroker: boolean
    isDownsamplingAllowed: boolean
    useSuggestedMinMax: boolean
    graphWidthInMs: number
}

// Graph constants
const DATA_DECIMATION_THRESHOLD = 150
const GRAPH_POINT_RADIUS = 1.5
const GRAPH_LINE_WIDTH = 1.5
const GRAPH_DRAW_DELAY_MS = 1_000
const COLOR_LIGHT_BLUE = '#7ca1c5'
const COLOR_BLUE = '#05315A'
const COLOR_DARK_GREEN = '#005E3C'
const COLOR_GREEN = '#16A468'
const COLORS = [COLOR_BLUE, COLOR_LIGHT_BLUE, COLOR_GREEN, COLOR_DARK_GREEN]
const SEEK_ASSUMPTION_TIME_DIFFERENCE_MS = 3_000

const PANEL_BASE_HEIGHT = 190
const PANEL_COLOR = 'remotive-primary-5-background'
const DEFAULT_SIGNAL_VALUE = { value: 'N/A', timestampeMs: 0 } as SignalValue

const DECIMATION_ENABLED = {
    enabled: true,
    algorithm: 'lttb',
    samples: DATA_DECIMATION_THRESHOLD,
    threshold: DATA_DECIMATION_THRESHOLD,
} as DecimationOptions

const DECIMATION_DISABLED = {
    enabled: false,
} as DecimationOptions

enum Playback {
    STOPPED = 'STOPPED',
    PAUSED = 'PAUSED ',
    PLAYING = 'PLAYING',
}

/**
 * a graph container that is used to visualize signal value
 *
 * @dev when updating properties during this components lifetime you MUST use Chart.js's chart registry to
 * get the chart state, i.e Chart.getChart(key). When setting up and destorying the chart you MUST use the local
 * variable _chart
 * @param props properties that this graph should use
 *
 * @returns a graph component
 */
export default function MultiTypeSignalContainer(props: MultiTypeSignalContainerProps) {
    // Panel settings
    const [showPopover, setShowPopover] = useState<boolean>(false)

    // Signal
    const [signalTypeHint, setSignalTypeHint] = useState<SignalType>()
    const [signalParsingType, setSignalParsingType] = useState<ParsingType>(ParsingType.HEX)
    const signalParsingTypeRef = useRef<ParsingType>(signalParsingType)
    const [currentSignalValue, setCurrentSignalValue] = useState<SignalValue>(DEFAULT_SIGNAL_VALUE)
    const subscriptionId = useRef<SubscriptionId>()
    const [shouldPauseStreaming, setShouldPauseStreaming] = useState<boolean>(false)
    const shouldPauseStreamingRef = useRef<boolean>(false)

    // Chart
    const chartRef = useRef<ChartJS<'line', { x: number; y: string | number | number[] | string[] }[], string>>(null)
    const bufferedSignalValues = useRef<Array<SignalValue>>([])
    const [dataToPlot, setDataToPlot] = useState<Array<{ x: number; y: number }>>([])
    const [hiddenDatasetUsedForStreamingEffect, setHiddenDatasetUsedForStreamingEffect] = useState<
        Array<{ x: number; y: number }>
    >([])
    const [decimation, setDecimation] = useState<DecimationOptions>(DECIMATION_DISABLED)
    const previousPlaybackMode = useRef<Playback>(Playback.PAUSED)
    const previousPlaybackStateOffset = useRef<number>()
    const shouldClearGraphOnNextCallback = useRef<boolean>(false)
    const GRAPH_COLOR = useRef<string>(COLORS[Math.floor(Math.random() * COLORS.length)])

    const updateChart = () => {
        if (!shouldPauseStreaming) {
            const latestValue = bufferedSignalValues.current[bufferedSignalValues.current.length - 1]
            updatePrintedValue(latestValue)
            updateGraphValues(latestValue)
        }
    }

    useInterval(updateChart, 1000 / props.frameRate)

    useEffect(() => {
        console.log(`Mounted graph container ${props.containerKey}`)
        subscriptionId.current = addPendingSubscription(
            props.signalFilter.signalName,
            props.signalFilter.parentFrame.namespace,
            (signalValue, signalType) => onSignalValueCallback(signalValue, signalType, signalParsingTypeRef.current)
        )
        return () => {
            removeSubscription(subscriptionId.current)
        }
    }, [])

    useEffect(() => {
        signalParsingTypeRef.current = signalParsingType
    }, [signalParsingType])

    useEffect(() => {
        chartRef.current?.update('none')
    }, [decimation])

    useEffect(() => {
        const isPaused = (props.playbackState && !props.playbackState?.isPlaying()) || props.pauseLiveState
        setShouldPauseStreaming(isPaused)
        shouldPauseStreamingRef.current = isPaused
    }, [props.playbackState, props.pauseLiveState])

    useEffect(() => {
        if (props.playbackState?.isStopped()) {
            setDecimation(DECIMATION_DISABLED)
            bufferedSignalValues.current = []
            previousPlaybackMode.current = Playback.STOPPED
        }
        if (props.playbackState?.isPlaying()) {
            if (shouldClearGraphOnNextCallback.current || previousPlaybackMode.current === Playback.STOPPED) {
                clearGraph()
                shouldClearGraphOnNextCallback.current = false
            }
            previousPlaybackMode.current = Playback.PLAYING
        }
        if (props.playbackState?.isPaused()) {
            setDecimation(DECIMATION_DISABLED)
            previousPlaybackMode.current = Playback.PAUSED
        }

        checkIfSeekHasBeenPerformed(previousPlaybackStateOffset.current, props.playbackState)
        previousPlaybackStateOffset.current = props.playbackState?.currentOffsetDurationMs()
    }, [props.playbackState])

    useEffect(() => {
        /* We will get a signal type hint as soon as the broker recieves a signal from the broker. 
        We will try to pick the best parsing strategy based on the signal type hint */
        if (signalTypeHint !== undefined) {
            switch (signalTypeHint) {
                case SignalType.NUMBER:
                    setSignalParsingType(ParsingType.DECIMAL_NUMBER)
                    break

                case SignalType.ARBITRATION:
                    setSignalParsingType(ParsingType.ARBITRATION)
                    break

                default:
                case SignalType.RAW:
                    setSignalParsingType(ParsingType.HEX)
                    break
            }
        }
    }, [signalTypeHint])

    const checkIfSeekHasBeenPerformed = (
        previousPlaybackStateOffset: number | undefined,
        currentPlaybackState: PlaybackState | undefined
    ) => {
        if (previousPlaybackStateOffset !== undefined && currentPlaybackState !== undefined) {
            const timeDifference = previousPlaybackStateOffset - currentPlaybackState.currentOffsetDurationMs()
            if (Math.abs(timeDifference) > SEEK_ASSUMPTION_TIME_DIFFERENCE_MS) {
                shouldClearGraphOnNextCallback.current = true
            }
        }
    }

    const clearGraph = () => {
        bufferedSignalValues.current = []
        setHiddenDatasetUsedForStreamingEffect([])
        setDataToPlot([])
        chartRef.current?.update('none')
    }

    const updatePrintedValue = (latestValue: SignalValue) => {
        if (latestValue !== undefined) {
            setCurrentSignalValue(latestValue)
        }
    }

    const updateGraphValues = (latestValue: SignalValue) => {
        if (chartRef.current !== null) {
            plotNewData(latestValue)
            activateDownsamplingIfNecessary()
            chartRef.current.update('none')
        }
    }

    const plotNewData = (latestValue: SignalValue) => {
        let newDataToPlot: { x: number; y: number }[] = []
        if (latestValue !== undefined) {
            // This is for the dataset that is actually visualized

            const cutOffTimestamp =
                latestValue.timestampeMs - props.graphWidthInMs - GRAPH_DRAW_DELAY_MS - GRAPH_DRAW_DELAY_MS * 2
            newDataToPlot = dataToPlot
                .concat(bufferedSignalValues.current.map((it) => convertSignalToXYValue(it)))
                .filter((it) => {
                    return it.x > cutOffTimestamp
                })
            setDataToPlot(newDataToPlot)
            bufferedSignalValues.current = []
        }
        if (dataToPlot.length > 0) {
            // This is for the hidden dataset
            const mostRecentlyPlottedValue =
                newDataToPlot.length > 0
                    ? newDataToPlot[newDataToPlot.length - 1]
                    : dataToPlot.length > 0
                    ? dataToPlot[dataToPlot.length - 1]
                    : undefined
            const latestTimestamp = Math.max(
                dataToPlot[dataToPlot.length - 1].x,
                hiddenDatasetUsedForStreamingEffect.length === 0 ? dataToPlot[dataToPlot.length - 1].x : 0,
                hiddenDatasetUsedForStreamingEffect.length > 0
                    ? hiddenDatasetUsedForStreamingEffect[hiddenDatasetUsedForStreamingEffect.length - 1].x
                    : 0
            )
            if (latestTimestamp > 0) {
                setHiddenDatasetUsedForStreamingEffect([
                    {
                        x: latestTimestamp + 1000 / props.frameRate,
                        y: mostRecentlyPlottedValue?.y ?? 0,
                    },
                ])
            }
        }
    }

    const activateDownsamplingIfNecessary = () => {
        if (chartRef.current !== null) {
            setDecimation(
                props.isDownsamplingAllowed && dataToPlot.length > DATA_DECIMATION_THRESHOLD
                    ? DECIMATION_ENABLED
                    : DECIMATION_DISABLED
            )
        }
    }

    const convertSignalToXYValue = (signalValue: SignalValue) => {
        return {
            x: signalValue.timestampeMs,
            y: Number(signalValue.value),
        }
    }

    const onSignalValueCallback = (signalValue: SignalValue, signalType: SignalType, parsingType: ParsingType) => {
        setSignalTypeHint(signalType)
        props.addSignalToHistoryFunction(signalValue, parsingType)
        bufferedSignalValues.current.push(signalValue)
    }

    const signalValueToString = (signalValue: SignalValue): string => {
        return SignalValueParser.parse({
            parsingType: signalParsingType,
            signalValue,
        } as SignalWithParsingType).toString()
    }

    const getOptions = (): ChartOptions => {
        return {
            // Important for correct chart canvas size
            maintainAspectRatio: false,
            responsive: true,

            // Performance optimizations
            parsing: false,
            animation: false,
            normalized: true,
            spanGaps: true,

            // For activating tooltip
            interaction: {
                mode: 'nearest',
                axis: 'x',
                intersect: false,
            },

            // Data decimation and custom tooltip windows
            plugins: {
                decimation: decimation, // This is downsampling
                tooltip: {
                    callbacks: {
                        title: (tooltipItems: Array<TooltipItem<ChartType>>) =>
                            !decimation.enabled ? `${(tooltipItems[0].parsed.x * 1000).toString()} µs` : '',
                        afterTitle: (tooltipItems: Array<TooltipItem<ChartType>>) => {
                            const date = new Date(tooltipItems[0].parsed.x)
                            return utcDateTimeWithMillis(date)
                        },
                        footer: (tooltipItems: Array<TooltipItem<'line'>>) => {
                            if (!decimation.enabled) {
                                const context = tooltipItems[0]
                                const previous = (context?.dataset?.data?.[context.dataIndex - 1]?.valueOf() as Point)
                                    ?.x
                                const current = context?.parsed?.x
                                return previous ? `Δ +${current * 1000 - previous * 1000} µs` : 'N/A'
                            }
                            return ''
                        },
                    },
                },
                legend: {
                    display: false,
                },
                title: {
                    display: false,
                },
            },

            // Settings for the line between points
            elements: {
                line: {
                    fill: props.chartType === 'filled',
                    borderWidth: GRAPH_LINE_WIDTH,
                },
            },

            // Settings for X-axis and Y-axis
            scales: {
                x: {
                    display: dataToPlot.length > 0,
                    min:
                        hiddenDatasetUsedForStreamingEffect.length > 0
                            ? hiddenDatasetUsedForStreamingEffect[hiddenDatasetUsedForStreamingEffect.length - 1].x -
                              GRAPH_DRAW_DELAY_MS -
                              props.graphWidthInMs
                            : 0,
                    max:
                        hiddenDatasetUsedForStreamingEffect.length > 0
                            ? hiddenDatasetUsedForStreamingEffect[hiddenDatasetUsedForStreamingEffect.length - 1].x -
                              GRAPH_DRAW_DELAY_MS
                            : props.graphWidthInMs / 1000,
                    type: 'time',
                    time: {
                        unit: 'second',
                        minUnit: 'second',
                    },
                    ticks: {
                        callback(tickValue, index, ticks) {
                            const time = new Date(tickValue)
                            const timeString = `${time.getUTCHours().toString().padStart(2, '0')}:${time
                                .getUTCMinutes()
                                .toString()
                                .padStart(2, '0')}:${time.getUTCSeconds().toString().padStart(2, '0')}`
                            const amountOfTicks = ticks.length
                            // We have to do this check because 7/15 is rounded down and 7 ticks will fit most screens
                            if (amountOfTicks > 7) {
                                const moduloBase = Math.round(amountOfTicks / 15)
                                if (window.innerWidth < 450) {
                                    // Small screens and below, E.g. iPhones
                                    if ((Number(tickValue) / 1000) % (moduloBase * 5) === 0) {
                                        // On the default graph this is one tick per 5 seconds
                                        return timeString
                                    }
                                    return undefined
                                }
                                if (window.innerWidth < 680) {
                                    // Up to medium screens, E.g. tablets
                                    if ((Number(tickValue) / 1000) % (moduloBase * 3) === 0) {
                                        // On the default graph this is one tick per 3 seconds
                                        return timeString
                                    }
                                    return undefined
                                }
                                if (window.innerWidth < 1350) {
                                    // Up to large screens, almost full width of a 13" laptop
                                    if ((Number(tickValue) / 1000) % (moduloBase * 2) === 0) {
                                        // On the default graph this is one tick per 2 seconds
                                        return timeString
                                    }
                                    return undefined
                                }
                                // All other screens larger full width of 13" lapotop, one tick per second
                                if ((Number(tickValue) / 1000) % (moduloBase * 1) === 0) {
                                    // On the default graph this is one tick per second
                                    return timeString
                                }
                                return undefined
                            } else {
                                return timeString
                            }
                        },
                        stepSize: 1,
                        includeBounds: false,
                        autoSkip: false, // autoskip takes too much CPU, disable it for performance
                        maxRotation: 0,
                        minRotation: 0,
                        align: 'inner',
                        font: {
                            family: 'LexendDecaRegular',
                        },
                    },
                },
                y: {
                    display: true,
                    suggestedMax: props.useSuggestedMinMax ? props.signalFilter.maxValue : undefined,
                    suggestedMin: props.useSuggestedMinMax ? props.signalFilter.minValue : undefined,
                    ticks: {
                        font: {
                            family: 'LexendDecaRegular',
                        },
                        mirror: true,
                        align: 'end',
                    },
                },
            },
        } as ChartOptions
    }

    const data = () => {
        return {
            datasets: [
                {
                    radius: GRAPH_POINT_RADIUS,
                    label: props.signalFilter.signalName,
                    data: dataToPlot,
                    borderColor: GRAPH_COLOR.current,
                    backgroundColor: `${GRAPH_COLOR.current}B3`,
                },
                {
                    data: hiddenDatasetUsedForStreamingEffect,
                    label: 'Now',
                    hitRadius: 0,
                    hoverRadius: 0,
                    pointStyle: false, //Hidden
                },
            ],
        }
    }

    const getChart = () => {
        if (signalParsingType === ParsingType.DECIMAL_NUMBER) {
            return (
                <>
                    <Chart
                        id={props.containerKey}
                        type={'line'}
                        style={{ height: `${PANEL_BASE_HEIGHT - 34}`, width: '100%' }}
                        options={getOptions()}
                        ref={chartRef}
                        data={data()}
                    />
                </>
            )
        }
        return <></>
    }

    const getSignalVisualization = () => {
        switch (signalParsingType) {
            case ParsingType.DECIMAL_NUMBER:
                return (
                    <>
                        <div className="w-100">{getSignalFilter('rounded-top-1')}</div>
                        <div
                            style={{
                                height: `${chartRef.current !== undefined ? PANEL_BASE_HEIGHT - 34 : 0}px`,
                            }}
                            className={`rounded-bottom-2 ${PANEL_COLOR} `}
                        >
                            {getChart()}
                        </div>
                    </>
                )

            default:
                return (
                    <>
                        <div className="w-100">{getSignalFilter('rounded')}</div>
                    </>
                )
        }
    }

    const signalTypeIsArrayBased = () => {
        return signalTypeHint === SignalType.RAW
    }

    const signalTypeIsPlottable = () => {
        return signalTypeHint === SignalType.NUMBER
    }

    const signalTypeIsPrintable = () => {
        return signalTypeIsPlottable() || signalTypeHint === SignalType.ARBITRATION || signalTypeHint === SignalType.RAW
    }

    const dataDecimationWarning = () => {
        const tooltip = (
            <Tooltip>
                Graph data is being downsampled, there is too much data to efficiently plot while streaming.
            </Tooltip>
        )
        if (chartRef.current !== null && decimation.enabled) {
            return (
                <OverlayTrigger placement="left" overlay={tooltip}>
                    <a
                        href="https://github.com/sveinn-steinarsson/flot-downsample"
                        target="_blank"
                        className="text-decoration-none"
                    >
                        <div className="d-flex align-items-center">
                            <DownsamplingIcon className="remotive-primary-40-color" sx={{ fontSize: 15 }} />
                            <p className="d-none d-md-block m-0 ms-1 remotive-font-xs text-secondary ">Downsampling</p>
                        </div>
                    </a>
                </OverlayTrigger>
            )
        }
        return <></>
    }

    const parsingOptions = () => {
        return (
            <OverlayTrigger
                trigger="click"
                rootClose
                show={showPopover}
                onToggle={(newState: boolean) => setShowPopover(newState)}
                placement="top"
                overlay={popover}
            >
                <button
                    className="btn remotive-btn-no-bg remotive-btn-sm d-flex align-items-center"
                    title="Select signal parsing"
                >
                    <ParsingIcon sx={{ fontSize: 17 }} />
                </button>
            </OverlayTrigger>
        )
    }

    const closeGraphButton = () => {
        return (
            <button
                disabled={props.removeContainerFunction === undefined}
                className="btn remotive-btn-no-bg remotive-btn-sm d-flex align-items-center"
                title="Close signal visualization"
                onClick={() => props.removeContainerFunction?.()}
            >
                <Close sx={{ fontSize: 20 }} />
            </button>
        )
    }

    const getSignalFilter = (customClasses: string) => {
        return (
            <div className={`mx-0 border-0 ${customClasses} ${PANEL_COLOR}`}>
                <div className="d-flex justify-content-between">
                    <div className="d-flex d-inline-block align-items-center p-1 pb-0 text-truncate">
                        <div className="text-truncate">
                            <p
                                className="m-0 text-start remotive-primary-60-color lh-sm text-truncate"
                                style={{
                                    fontSize: '10px',
                                    marginBottom: '-2px',
                                }}
                            >
                                {props.signalFilter.signalName}
                            </p>
                            <p
                                className="m-0 text-start remotive-primary-40-color lh-1 text-truncate"
                                style={{ fontSize: '8px' }}
                            >
                                {props.signalFilter.parentFrame.namespace}
                            </p>
                        </div>
                        <div className="ms-2 text-truncate">
                            <p
                                className={`m-0 fs-6 pt-0 align-text-bottom remotive-primary-60-color text-truncate ${
                                    signalParsingType === ParsingType.BITS || signalParsingType === ParsingType.HEX
                                        ? 'font-monospace'
                                        : ''
                                }`}
                            >
                                {signalValueToString(currentSignalValue)}
                            </p>
                        </div>
                    </div>
                    <div>
                        <div className="d-flex align-items-center">
                            {dataDecimationWarning()}
                            {parsingOptions()}
                            {closeGraphButton()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const popover = (
        <Popover id="popover-basic-123" className="border-0 remotive-primary-0-background shadow">
            <Popover.Body style={{ zIndex: '0 !important' }} className="pb-2">
                <p className="text-center m-0 mb-0 lexend-bold remotive-font-sm">Signal value parsing</p>
                <p className="text-center m-0 mb-1 lexend-regular text-secondary remotive-font-xs lh-sm">
                    Select how the signal value <br />
                    should be parsed
                </p>
                <div className="text-center">
                    {signalTypeIsPrintable() && (
                        <button
                            className={`btn border-0 remotive-btn-sm ${
                                signalParsingType === ParsingType.ASCII
                                    ? 'remotive-btn-success'
                                    : 'remotive-btn-primary'
                            }`}
                            onClick={() => setSignalParsingType(ParsingType.ASCII)}
                            title="Parse signal as ASCII"
                        >
                            <p className="lexend-bold remotive-font-xxs py-1 m-0">{ParsingType.ASCII}</p>
                        </button>
                    )}

                    {signalTypeIsPrintable() && (
                        <button
                            style={{ height: 24 }}
                            className={`btn border-0 remotive-btn-sm ${
                                signalParsingType === ParsingType.HEX ? 'remotive-btn-success' : 'remotive-btn-primary'
                            }`}
                            onClick={() => setSignalParsingType(ParsingType.HEX)}
                            title="Parse signal as hexadecimal"
                        >
                            <p className="lexend-bold remotive-font-xxs py-1 m-0">{ParsingType.HEX}</p>
                        </button>
                    )}
                    {signalTypeIsPrintable() && (
                        <button
                            className={`btn border-0 remotive-btn-sm ${
                                signalParsingType === ParsingType.RAW ? 'remotive-btn-success' : 'remotive-btn-primary'
                            }`}
                            onClick={() => setSignalParsingType(ParsingType.RAW)}
                            title="No parsing, show raw value"
                        >
                            <p className="lexend-bold remotive-font-xxs py-1 m-0">{ParsingType.RAW}</p>
                        </button>
                    )}

                    {signalTypeIsArrayBased() && (
                        <button
                            className={`btn border-0 remotive-btn-sm ${
                                signalParsingType === ParsingType.BITS ? 'remotive-btn-success' : 'remotive-btn-primary'
                            }`}
                            onClick={() => setSignalParsingType(ParsingType.BITS)}
                            title="Parse signal as bits"
                        >
                            <p className="lexend-bold remotive-font-xxs py-1 m-0">{ParsingType.BITS}</p>
                        </button>
                    )}

                    {signalTypeIsPlottable() && (
                        <button
                            className={`btn border-0 remotive-btn-sm ${
                                signalParsingType === ParsingType.DECIMAL_NUMBER
                                    ? 'remotive-btn-success'
                                    : 'remotive-btn-primary'
                            }`}
                            onClick={() => setSignalParsingType(ParsingType.DECIMAL_NUMBER)}
                            title="Parse signal as decimal"
                        >
                            <p className="lexend-bold remotive-font-xxs py-1 m-0">{ParsingType.DECIMAL_NUMBER}</p>
                        </button>
                    )}
                </div>
            </Popover.Body>
        </Popover>
    )

    return (
        <>
            <Container fluid className="border-0 m-0 p-0" style={{ maxHeight: `${PANEL_BASE_HEIGHT}px` }}>
                {getSignalVisualization()}
            </Container>
        </>
    )
}
